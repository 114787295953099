<template>
  <div>
    <b-overlay
      :show="loading"
      rounded="sm"
    >
      <b-row>
        <b-col
          md="12"
          class="mb-3 text-right"
        >
          <b-button
            class=""
            variant="outline-primarySpore"
            @click="showFilter"
          >
            <feather-icon
              icon="FilterIcon"
            />
            <span class="align-middle" v-if="!show"> Mostrar filtros </span>
            <span class="align-middle" v-else > Ocultar filtros </span>
          </b-button>
        </b-col>
      </b-row>
      <b-card no-body v-if="show">
        <b-card-header class="pb-50">
          <h5>
            Filtrar
          </h5>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col
              cols="12"
              md="2"
              class="mb-md-0 mb-2"
            >
              <b-form-group
                label="Número Gabinete"
              >
                <b-form-input
                id="cabinetNumber"
                v-model="filters.cabinetNumber"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-md-0 mb-2"
            >
              <b-form-group
                label="Número Medidor"
              >
                <b-form-input
                id="meterNumber"
                v-model="filters.meterNumber"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
              class="mb-md-0 mb-2"
            >
              <b-form-group
                label="."
                style="color:#FFFFFF"
              >
                <b-button
                  variant="primarySpore"
                  @click="search"
                >
                  <feather-icon
                    icon="SearchIcon"
                    class="mr-50"
                  />
                  <span>Buscar</span>
                </b-button>
                <b-button
                  class="ml-1"
                  variant="danger"
                  @click="clear"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    class="mr-50"
                  />
                  <span>Limpiar</span>
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>{{ $t('Labels.Show') }}</label>
              <v-select
                v-model="filters.limit"
                :options="this.perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
                @input="getResponse"
              />
              <label>{{ $t('Labels.Registers') }}</label>
            </b-col>
            <b-col
              md="6"
              class="text-right"
            >
              <b-button
                v-if="response.length !== 0"
                class="mb-2"
                variant="primarySpore"
                @click="postRegisterAll"
              >
                <feather-icon
                  icon="RefreshCcwIcon"
                />
                Sincronizar todo
              </b-button>
            </b-col>
          </b-row>
        </div>
        <div class="text-center" v-if="this.response.length === 0">
          No hay registros que mostrar
        </div>
        <b-table
          v-if="response.length > 0"
          class="position-relative"
          hover
          responsive
          primary-key="id"
          show-empty
          :items="response"
          :fields="tableColumns"
        >
          <template #cell(Action)="row">
            <b-form-checkbox
              v-if="!isEmpty(row.item.registedAt)"
              v-model="row.detailsShowing"
              plain
              class="vs-checkbox-con"
              @change="row.toggleDetails"
            >
              <span class="vs-checkbox">
                <span class="vs-checkbox--check">
                  <i class="vs-icon feather icon-check" />
                </span>
              </span>
              <span class="vs-label">{{ row.detailsShowing ? 'Ocultar' : 'Mostrar' }}</span>
            </b-form-checkbox>
          </template>

          <template #row-details="row">
            <b-card>
              <b-row class="mb-1">
                <b-col
                  md="9"
                  class="mb-1"
                >
                  <b-row class="mb-1">
                    <b-col
                      md="6"
                      class="mb-1"
                    >
                      <feather-icon class="align-text-top" icon="CloudLightningIcon" size="25" /> &nbsp;
                      <strong>Corriente: </strong>&nbsp;{{ decimal(row.item.electricCurrent, 2) }}
                    </b-col>
                    <b-col
                      md="6"
                      class="mb-1"
                    >
                      <feather-icon class="align-text-top" icon="BatteryChargingIcon" size="25" /> &nbsp;
                      <strong>Almacenamiento de energía: </strong>&nbsp;{{ decimal(row.item.storedEnergy, 3) }}
                    </b-col>
                    <b-col
                      md="6"
                      class="mb-1"
                    >
                      <feather-icon class="align-text-top" icon="ActivityIcon" size="25" /> &nbsp;
                      <strong>Frecuencia: </strong>&nbsp;{{ decimal(row.item.frecuency, 2) }}
                    </b-col>
                    <b-col
                      md="6"
                      class="mb-1"
                    >
                      <feather-icon class="align-text-top" icon="ZapIcon" size="25" /> &nbsp;
                      <strong>Voltaje: </strong>&nbsp;{{ decimal(row.item.voltage, 2) }}
                    </b-col>
                    <b-col
                      md="6"
                      class="mb-1"
                    >
                      <feather-icon class="align-text-top" v-if="parseInt(row.item.status) === 1" icon="ZapIcon" size="25" />&nbsp;
                      <feather-icon class="align-text-top" v-if="parseInt(row.item.status) === 0" icon="ZapOffIcon" size="25"/>&nbsp;
                      <strong>Estado: </strong>&nbsp;{{parseInt(row.item.status) === 1 ? 'con servicio' : 'sin servicio' }}
                    </b-col>
                  </b-row>
                </b-col>
                <b-col
                  md="3"
                  class="mb-1 text-righ"
                >
                  <qrcode-vue :value="getValue(row.item.number.replace(/^(0)+/, ''), row.item.registedAt)" size="200" level="H" />
                </b-col>
              </b-row>

              <b-button
                variant="outline-info"
                @click="row.toggleDetails"
              >
                Ocultar Detalle
              </b-button>
            </b-card>
          </template>

          <template #cell(number)="data">
            <div class="text-nowrap selectable" v-if="data.item.number">
              <b-avatar
                v-if="data.item.number"
                size="32"
                class="avatar-border-2 box-shadow-1"
                variant="transparent"
              >
                <b-img :src="require(`@/assets/images/spore-icons/hash.svg`)" height="20"/>
              </b-avatar>
              <span class="align-text-top text-capitalize ml-1">{{ data.item.number.replace(/^(0)+/, '') }}</span>
            </div>
          </template>
          <template #cell(electricCurrent)="data">
            <div class="text-nowrap selectable" v-if="data.item.electricCurrent">
              <b-avatar
                v-if="data.item.electricCurrent"
                size="32"
                class="avatar-border-2 box-shadow-1"
                variant="transparent"
              >
                <b-img :src="require(`@/assets/images/spore-icons/energy.svg`)" height="20"/>
              </b-avatar>
              <span class="align-text-top text-capitalize ml-1">{{ decimal(data.item.electricCurrent, 2) }} A</span>
            </div>
          </template>
          <template #cell(position)="data">
            <div class="text-nowrap selectable" v-if="data.item.position">
              <b-avatar
                v-if="data.item.position"
                size="32"
                class="avatar-border-2 box-shadow-1"
                variant="transparent"
              >
                <b-img :src="require(`@/assets/images/spore-icons/alias.svg`)" height="20"/>
              </b-avatar>
              <span class="align-text-top text-capitalize ml-1">{{ data.item.position }}</span>
            </div>
          </template>
          <template #cell(status)="data">
            <div class="text-nowrap selectable">
              <b-avatar
                v-if="data.item.status"
                size="32"
                class="avatar-border-2 box-shadow-1"
                :variant="parseInt(data.item.status) === 1 ? 'success' : 'secondary'"
              >
                <feather-icon class="align-text-top" v-if="parseInt(data.item.status) === 1" icon="ZapIcon" size="18" />
                <feather-icon class="align-text-top" v-if="parseInt(data.item.status) === 0" icon="ZapOffIcon" size="18"/>
              </b-avatar>
              <b>
                <span class="align-text-top text-capitalize ml-1" :class="parseInt(data.item.status) === 1 ? 'text-success' : ''">{{parseInt(data.item.status) === 1 ? 'con servicio' : 'sin servicio' }}</span>
              </b>
            </div>
          </template>

          <template #cell(register)="data">
            <div class="text-register selectable">
              <b-avatar
                v-if="data.item.register"
                size="32"
                class="avatar-border-2 box-shadow-1"
                variant="success"
              >
                <feather-icon class="align-text-top" icon="CheckCircleIcon" size="18" />
              </b-avatar>
              <b-button
                v-if="isEmpty(data.item.register)"
                variant="warning"
                @click="postRegisterOne(data.item.uuid)"
              >
                <feather-icon
                  icon="RefreshCcwIcon"
                />
              </b-button>
            </div>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
            </b-col>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="this.totalRows"
                :per-page="this.filters.limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton, BTable, BPagination, VBTooltip, BFormGroup, BFormInput, BAvatar, BImg, BOverlay, BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import request from '@/libs/request/index'
import { formatDateLanguages, empty, decimales } from '@/libs/tools/helpers'
import QrcodeVue from 'qrcode.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BTable,
    BPagination,
    vSelect,
    BCardHeader,
    BFormGroup,
    BAvatar,
    BImg,
    BOverlay,
    BFormInput,
    BFormCheckbox,
    QrcodeVue
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data () {
    return {
      show: false,
      loading: false,
      tableColumns: [
        { key: 'action', label: 'SiNaMed' },
        { key: 'number', label: 'Número', sortable: true },
        { key: 'electricCurrent', label: 'Corriente', sortable: true },
        { key: 'position', label: 'Posición', sortable: true },
        { key: 'status', label: 'Estatus', sortable: true },
        { key: 'register', label: 'Registrado', sortable: true }
      ],
      totalRows: 0,
      currentPage: 1,
      count: 0,
      perPageOptions: [10, 25, 50, 100],
      response: [],
      filters: {
        registed: 'N',
        limit: 20,
        offset: 0,
        cabinetNumber: '',
        meterNumber: '',
        byColumn: 'transmitedAt',
        byOrder: 'desc'
      }
    }
  },
  methods: {
    decimal (val, n) {
      return decimales(val, n)
    },
    getValue (numero, register, transmited) {
      return `Número de medidor: ${numero} | Fecha de registro: ${register} | Fecha de transmisión: ${transmited}`
    },
    isEmpty (val) {
      return empty(val)
    },
    async getResponse () {
      this.response = []
      const params = {
        url: '/meters/register/list',
        method: 'GET',
        params: this.filters
      }
      await request(params).then(data => {
        for (let index = 0; index < data.data.data.rows.length; index++) {
          this.response.push({
            uuid: data.data.data.rows[index].uuid,
            number: data.data.data.rows[index].number,
            electricCurrent: data.data.data.rows[index].electricCurrent,
            position: data.data.data.rows[index].position,
            status: data.data.data.rows[index].status,
            register: data.data.data.rows[index].registedAt
          })
        }
        this.totalRows = data.data.data.count
        this.count = data.data.data.rows.length
      })
    },
    showFilter () {
      this.show = !this.show
    },
    format (date) {
      return formatDateLanguages(date, 'hour')
    },
    search () {
      this.filters.offset = 0
      this.currentPage = 1
      this.getResponse()
    },
    clear () {
      this.filters.offset = 0
      this.currentPage = 1
      this.filters.cabinetNumber = ''
      this.filters.meterNumber = ''
      this.getResponse()
    },
    async postRegisterAll () {
      this.$swal({
        title: '¿Estas seguro?',
        text: 'Se sincronizará todos los medidores con SiNaMed',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async result => {
        if (result.value) {
          const params = {
            url: `/meters/register/masive?registed=N&cabinetNumber=${this.filters.cabinetNumber}&meterNumber=${this.filters.meterNumber}`,
            method: 'POST'
          }
          await request(params).then(data => {
            this.response = data.data.data.rows
          })
        }
      })
    },
    postRegisterOne (uuid) {
      this.$swal({
        title: '¿Estas seguro?',
        text: 'Se sincronizará medidor con SiNaMed',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async result => {
        if (result.value) {
          const params = {
            url: `/meters/${uuid}`,
            method: 'POST'
          }
          await request(params).then(data => {
            this.getResponse()
          })
        }
      })
    }
  },
  created () {
    this.getResponse()
  },
  watch: {
    currentPage (val) {
      this.filters.offset = (val - 1) * this.filters.limit
      this.getResponse()
    },
    byColumn (val) {
      this.filters.byColumn = val
      this.currentx = 1
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
